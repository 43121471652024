//
//
//
//
//
//
//
//
//
//
//

import createUrl from '~/utils/mixins/createUrl'

export default {
    name: 'CoProfileLink',
    mixins: [createUrl],
    props: {
        user: {
            type: Object,
            required: true
        }
    },
    computed: {
        isLinkAllowed () {
            return this.user?.isSeller && !this.user?.isBannedSeller && !this.user?.isDeleted
        }
    }
}
