//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, computed } from '@nuxtjs/composition-api'

export default defineComponent({
    props: {
        rate: {
            type: [String, Number],
            default: '0'
        },
        color: {
            type: String,
            default: '#444444'
        },
        size: {
            type: String,
            default: '13'
        }
    },
    setup (props) {
        const rateValue = +props.rate
        const rateInteger = Math.floor(rateValue)
        const rateRemainder = Math.round((rateValue - rateInteger) * 10)

        const offset = computed(() => {
            return `${rateRemainder > 0 ? 20 + rateRemainder * 6 : 0}%`
        })

        return {
            offset,
            rateValue,
            rateInteger
        }
    }
})
