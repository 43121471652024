//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent } from '@nuxtjs/composition-api'
import { mapGetters } from 'vuex'
import CoProfileLink from '~/components/common/CoProfileLink'
import { addHighlight } from '~/utils/helpers'
import { toEntities } from '~/utils/html-entities'

export default defineComponent({
    name: 'IndLiveReviewCard',
    components: { CoProfileLink },
    props: {
        review: {
            type: Object,
            required: true
        }
    },
    computed: {
        ...mapGetters(['isMobile'])
    },
    methods: {
        toEntities,
        addHighlight
    }
})
