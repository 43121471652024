//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, ref, computed, useContext, useFetch, useStore } from '@nuxtjs/composition-api'
import IndLiveReviewCard from '~/components/index/IndLiveReviewCard.vue'
import { keysToCamel, addHighlight } from '~/utils/helpers'
import { toEntities } from '~/utils/html-entities'

export default defineComponent({
    name: 'IndLiveReviews',
    components: { IndLiveReviewCard },
    fetchOnServer: false,
    setup () {
        const reviews = ref([])

        const { $api, $handleErrors } = useContext()
        const store = useStore()

        const isMobile = computed(() => store.getters.isMobile)
        const reviewsNumberToDisplay = computed(() => ({
            desktop: 6,
            mobile: 3
        })[isMobile.value ? 'mobile' : 'desktop'])

        const activeReviewsSliceTo = ref(0)
        const reviewsActive = computed(() => reviews.value.slice(activeReviewsSliceTo.value - reviewsNumberToDisplay.value, activeReviewsSliceTo.value))

        const reviewsChangeInterval = ref(null)

        const changeReviews = () => {
            const newExpectedValue = !activeReviewsSliceTo.value ? reviewsNumberToDisplay.value : activeReviewsSliceTo.value + reviewsNumberToDisplay.value
            activeReviewsSliceTo.value = newExpectedValue <= reviews.value.length ? newExpectedValue : reviewsNumberToDisplay.value
        }

        const initReviewsChange = () => {
            reviewsChangeInterval.value = setInterval(changeReviews, 5000)
        }

        const onReviewsMouseover = () => {
            clearInterval(reviewsChangeInterval.value)
        }

        const onReviewsMouseout = initReviewsChange

        useFetch(async () => {
            try {
                const response = await $api.get('guests/testimonials/ratings-list', { per_page: 6 * 4, unique_only: 1 })
                reviews.value = keysToCamel(response.data)
                initReviewsChange()
                changeReviews()
                store.commit('app/setReviewsCount', keysToCamel(response.meta)?.totalItems)
            } catch (err) {
                $handleErrors(err)
            }
        })

        return {
            isMobile,
            reviews,
            reviewsActive,
            reviewsNumberToDisplay,
            activeReviewsSliceTo,
            onReviewsMouseover,
            onReviewsMouseout
        }
    },
    methods: {
        toEntities,
        addHighlight
    }
})
