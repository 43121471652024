export default {
    methods: {
        createSolodealUrl ({ id, uid }, isAbsolute = false) {
            return `${isAbsolute ? this.$config.siteProtocol + '://' + this.$config.siteHost : ''}/solodeals/${id}-${uid}`
        },
        createProfileUrl (uid, isAbsolute) {
            return (isAbsolute ? this.$config.siteProtocol + '://' + this.$config.siteHost : '') + '/p/' + uid
        }
    }
}
